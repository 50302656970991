import { readCookie } from "./readCookie";

// VS Tracking Preference
const vsOptOutGdprCookieName = "vsOptOutGdpr";
const vsOptOutCcpaCookieName = "vsOptOutCcpa";

export const trackingPreference = {
  hasOptedOutOfTracking() {
    return !!(
      readCookie(vsOptOutGdprCookieName) || readCookie(vsOptOutCcpaCookieName)
    );
  },
};
