import { XF } from "./XF";
import { trackingPreference } from "./tracking-preference";

// VS ML DMT
window.DmTracker =
  window.DmTracker ||
  function DmTracker() {
    //
    // Lang
    //
    const Lang = {
      isDefined(o) {
        return typeof o !== "undefined";
      },

      isNull(o) {
        return o === null;
      },

      exists(o) {
        return this.isDefined(o) && !this.isNull(o);
      },

      isString(o) {
        return typeof o === "string" || o instanceof String;
      },

      Date: {
        now() {
          return new Date().getTime();
        },
      },
    };

    //
    // Uuid
    //
    const Uuid = {
      createV4() {
        /* eslint-disable no-bitwise */
        // ref: https://stackoverflow.com/questions/105034/how-to-create-guid-uuid/2117523#2117523
        if (window.crypto && window.crypto.getRandomValues) {
          return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
            (
              c ^
              (window.crypto.getRandomValues(new Uint8Array(1))[0] &
                (15 >> (c / 4)))
            ).toString(16)
          );
        }
        if (window.msCrypto && window.msCrypto.getRandomValues) {
          return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
            (
              c ^
              (window.msCrypto.getRandomValues(new Uint8Array(1))[0] &
                (15 >> (c / 4)))
            ).toString(16)
          );
        }
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
          const r = (Math.random() * 16) | 0;
          const v = c === "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        });
        /* eslint-enable no-bitwise */
      },
    };

    //
    // VsStorage - uses localStorage
    //
    const VsStorage = {
      getObject(key) {
        return window.localStorage.getItem(key);
      },
      setObject(key, value) {
        window.localStorage.setItem(key, value);
      },
      removeObject(key) {
        window.localStorage.removeItem(key);
      },
    };

    //
    // Global
    //
    const Global = {
      tracking: "vsDmtTrackId",

      getTrackingId() {
        // TODO: do we ever want to expire this trackingId?
        let obj = VsStorage.getObject(this.tracking);
        if (obj) {
          obj = JSON.parse(obj);
        } else {
          obj = {
            trackingId: Uuid.createV4(),
            createdAt: Lang.Date.now(),
          };
          VsStorage.setObject(this.tracking, JSON.stringify(obj));
        }
        return obj.trackingId;
      },

      getGoogleAnalyticsIds() {
        if (!isGoogleAnalyticsInitialized()) {
          return [];
        }
        const ids = [];
        window.ga.getAll().forEach((tracker) => {
          ids.push({
            clientId: tracker.get("clientId"),
            trackingId: tracker.get("trackerId"),
          });
        });
        return ids;
      },
    };

    //
    // Queue - saves the records in internally for later processing
    //
    const Queue = {
      key: "vsDmtQueueArr",

      add(obj) {
        if (!obj) {
          return;
        }
        const objInString = JSON.stringify(obj);
        const arr = this.getAll();
        for (let i = 0; i < arr.length; i++) {
          if (objInString === JSON.stringify(arr[i])) {
            return;
          }
        }
        arr.push(obj);
        VsStorage.setObject(this.key, JSON.stringify(arr));
      },

      remove(obj) {
        if (!obj) {
          return;
        }
        const oldArr = this.getAll();
        if (!oldArr || oldArr.length === 0) {
          return;
        }
        const objInString = JSON.stringify(obj);
        const newArr = [];
        for (let i = 0; i < oldArr.length; i++) {
          if (objInString !== JSON.stringify(oldArr[i])) {
            newArr.push(oldArr[i]);
          }
        }
        // whether array is empty or not, do not remove it from the queue
        VsStorage.setObject(this.key, JSON.stringify(newArr));
      },

      getAll() {
        const elements = VsStorage.getObject(this.key);
        if (!elements) {
          return [];
        }
        return JSON.parse(elements);
      },
    };

    //
    // Browser Object Model
    //
    const Bom = {
      isAnchor(o) {
        return o.constructor === HTMLAnchorElement;
      },

      Page: {
        getUrl() {
          return window.location.pathname + window.location.search;
        },
      },

      getTrackedData(node, trackedData) {
        const result = !Lang.exists(trackedData) ? {} : trackedData;
        if (node.nodeType === 1) {
          // 1 is element node
          const dmtJson = node.getAttribute("data-dmt-json");
          if (!Lang.isNull(dmtJson)) {
            try {
              const dmtObj = JSON.parse(dmtJson);
              Object.keys(dmtObj).forEach((key) => {
                result[key] = dmtObj[key];
              });
            } catch (e) {
              console.error(e);
            }
          }
        }
        return result;
      },

      traverseUpForTrackedData(node) {
        let trackedData = {};
        let currNode = node;
        while (currNode !== document.body) {
          trackedData = this.getTrackedData(currNode, trackedData);
          currNode = currNode.parentNode;
        }
        return trackedData;
      },
    };

    //
    // Tracker
    //
    function Tracker() {
      // intentionally left blank and extended below
    }

    Tracker.asyncCaptureEvent = async function asyncCaptureEvent(
      captureRequest
    ) {
      if (!captureRequest.trackingId) {
        captureRequest.trackingId = Global.getTrackingId();
      }

      if (!captureRequest.googleAnalyticsIds) {
        await asyncWaitForGA();
        captureRequest.googleAnalyticsIds = Global.getGoogleAnalyticsIds();
      }
      Queue.add(captureRequest);

      const payload = JSON.stringify(captureRequest);
      $.ajax({
        url: "/data-mining-tracker",
        data: {
          _xfResponseType: "json",
          _xfToken: XF.config.csrf,
          payload,
        },
        type: "post",
        cache: false,
        dataType: "json",
        global: false,
        contentType: "application/x-www-form-urlencoded; charset=UTF-8",
      }).done(() => {
        Queue.remove(captureRequest);
      });
    };

    function isGoogleAnalyticsInitialized() {
      return typeof window.ga === "function" && window.ga.loaded;
    }

    async function asyncWaitForGA() {
      const gaWaitTimeoutMS = 500;
      const checkGAInitializedIntervalMS = 50;
      const maxNumRequests = Math.floor(
        gaWaitTimeoutMS / checkGAInitializedIntervalMS
      );
      const waitPromise = new Promise<void>((resolve, reject) => {
        let i = 0;
        if (isGoogleAnalyticsInitialized()) {
          resolve();
          return;
        }

        const checkGoogleAnalyticsInterval = setInterval(() => {
          i++;
          if (isGoogleAnalyticsInitialized() || i >= maxNumRequests) {
            clearInterval(checkGoogleAnalyticsInterval);
            if (isGoogleAnalyticsInitialized()) {
              resolve();
              return;
            }
            reject();
          }
        }, checkGAInitializedIntervalMS);
      });
      await waitPromise;
    }

    function captureContentViewFromElement(dmtPageElement) {
      if (!dmtPageElement) {
        return;
      }
      const contentViewRequest = {
        timestampEpoch: Lang.Date.now(),
        pathUrl: Bom.Page.getUrl(),
        eventType: "contentView",
      };
      const viewEventInfo = Bom.getTrackedData(dmtPageElement);
      if (Lang.isDefined(viewEventInfo)) {
        contentViewRequest.eventInfo = viewEventInfo;
      }
      Tracker.asyncCaptureEvent(contentViewRequest);
    }

    function getAdjustedViewThresholdBasedOnWindowSize(
      element,
      intersectionRatio
    ) {
      return element.offsetHeight <= window.innerHeight
        ? intersectionRatio
        : (intersectionRatio * window.innerHeight) / element.offsetHeight;
    }

    function captureContentViewIfElementInViewPort(
      dmtPageElement,
      intersectionRatio
    ) {
      if (!window.IntersectionObserver || !dmtPageElement) {
        return;
      }

      const config = {
        threshold: getAdjustedViewThresholdBasedOnWindowSize(
          dmtPageElement,
          intersectionRatio
        ),
      };

      const intersectionObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // The observer is disconnected here so that it doesn't trigger
            // every time the user scrolls around.
            intersectionObserver.disconnect();
            captureContentViewFromElement(dmtPageElement);
          }
        });
      }, config);

      intersectionObserver.observe(dmtPageElement);
    }

    function processQueueBacklog() {
      const backlog = Queue.getAll();
      for (let i = 0; i < backlog.length; i++) {
        Tracker.asyncCaptureEvent(backlog[i]);
      }
    }

    function initializeClickThroughListener() {
      document.addEventListener(
        "click",
        (event) => {
          const element = event.target;
          if (!Bom.isAnchor(element)) {
            return;
          }

          let aHref = element.getAttribute("href");
          if (!Lang.exists(aHref)) {
            aHref = "";
          }

          // Track current clickthrough
          const clickthroughRequest = {
            timestampEpoch: Lang.Date.now(),
            pathUrl: aHref,
            eventType: "clickthrough",
          };
          const clickEventInfo = Bom.traverseUpForTrackedData(element);
          if (Lang.isDefined(clickEventInfo)) {
            clickthroughRequest.eventInfo = clickEventInfo;
          }
          Tracker.asyncCaptureEvent(clickthroughRequest);
        },
        false
      );
    }

    function initializeContentViewObserver(
      dmtPageAttribute,
      dmtIntersectingRatioAttribute
    ) {
      const dmtPageAttributeObserver = new MutationObserver(() => {
        const dmtPageElement = document.querySelector(`[${dmtPageAttribute}]`);
        if (!Lang.exists(dmtPageElement)) {
          return;
        }

        if (dmtPageElement.hasAttribute(dmtIntersectingRatioAttribute)) {
          const viewThreshold = parseFloat(
            dmtPageElement.getAttribute(dmtIntersectingRatioAttribute)
          );
          captureContentViewIfElementInViewPort(dmtPageElement, viewThreshold);
        } else {
          captureContentViewFromElement(dmtPageElement);
        }
        dmtPageElement.removeAttribute(dmtPageAttribute);
      });

      const dmtObserverConfig = {
        subtree: true,
        childList: true,
        attributeFilter: [dmtPageAttribute],
      };
      dmtPageAttributeObserver.observe(document, dmtObserverConfig);
    }

    //
    // main
    //
    try {
      if (window.localStorage && window.location) {
        // Selector
        const dmtPageAttribute = "data-dmt-tag";

        // What ratio of the element should be in the viewport (Value would be from 0 - 1)
        const dmtIntersectingRatioAttribute = "data-dmt-intersect-view-ratio";

        processQueueBacklog();
        initializeContentViewObserver(
          dmtPageAttribute,
          dmtIntersectingRatioAttribute
        );
        initializeClickThroughListener();
      }
    } catch (e) {
      console.error(e);
    }
  };

document.addEventListener("DOMContentLoaded", () => {
  if (!trackingPreference.hasOptedOutOfTracking()) {
    window.DmTracker();
  }
});
